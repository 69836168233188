/* eslint react/prop-types: 0 */
import React from "react";

//https://github.com/treyhuffine/lightbox-react/blob/master/src/lightbox-react.js
//https://reactjsexample.com/lightbox-for-components-or-images-built-for-react/
import LightboxReact from "lightbox-react";
import "lightbox-react/style.css";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Lightbox = ({
  images,
  selectedImage,
  handleClose,
  handlePrevRequest,
  handleNextRequest,
}) => {
  const array = [];

  images.forEach((image) =>
    array.push(
      <GatsbyImage
        className="w-full h-full"
        image={getImage(image.imagefile.localFile.childImageSharp)}
        alt="Project Image"
        imgStyle={{ objectFit: "contain", padding: "2vh 0" }}
        style={{ margin: "0 auto", maxHeight: "100vh", marginTop: "0px" }}
      />
    )
  );

  return (
    <LightboxReact
      enableZoom={false}
      clickOutsideToClose={true}
      mainSrc={array[selectedImage]}
      nextSrc={array[(selectedImage + 1) % array.length]}
      prevSrc={array[(selectedImage + array.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={handlePrevRequest(selectedImage, array.length)}
      onMoveNextRequest={handleNextRequest(selectedImage, array.length)}
    />
  );
};

export default Lightbox;
