import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { isMobile, isTablet } from "react-device-detect";

export default function ProjectImage({ image, handleOpen, i }) {
  const imageNode = getImage(image.imagefile?.localFile);
  const h = image.imagefile.localFile.childImageSharp.original.height;
  const w = image.imagefile.localFile.childImageSharp.original.width;
  return (
    <div
      className="pb-6 cursor-pointer"
      onClick={!isMobile || isTablet ? handleOpen(i) : null}
    >
      {" "}
      <div className={`${h / w < 1 ? "" : "w-8/12 mx-auto"}`}>
        <GatsbyImage image={imageNode} alt="Project Image" />
      </div>
    </div>
  );
}
