import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectImage from "../components/ProjectImage";
import LightBox from "../components/Lightbox";

export default function PageTemplate({ data }) {
  const [showLightbox, setShowLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (i) => () => {
    setShowLightbox(true);
    setSelectedImage(i);
  };
  const handleClose = () => {
    setShowLightbox(false);
    setSelectedImage(null);
  };
  const handlePrevRequest = (i, length) => () => {
    setSelectedImage((i - 1 + length) % length);
  };
  const handleNextRequest = (i, length) => () => {
    setSelectedImage((i + 1) % length);
  };

  const title = data.wpPage.title;
  return (
    <Layout>
      <SEO title={title} />
      <div className="lg:max-w-4xl mx-auto md:px-16">
        <div className="pb-12 md:pb-10">
          <h1 className="text-center uppercase">{title}</h1>
        </div>

        <div className="page-images">
          {data.wpPage.images.image.map((post, i) => {
            return (
              <ProjectImage
                key={i}
                image={post}
                handleOpen={handleOpen}
                i={i}
              />
            );
          })}
        </div>
        {showLightbox && selectedImage !== null && (
          <LightBox
            images={data.wpPage.images.image}
            handleClose={handleClose}
            handleNextRequest={handleNextRequest}
            handlePrevRequest={handlePrevRequest}
            selectedImage={selectedImage}
          />
        )}
      </div>
      <div className="text-center pb-8">
        <a href="#top">
          <button>To the top</button>
        </a>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      slug

      images {
        image {
          imagefile {
            localFile {
              childImageSharp {
                original {
                  src
                  height
                  width
                }

                gatsbyImageData(
                  quality: 80
                  placeholder: DOMINANT_COLOR
                  layout: CONSTRAINED
                  maxWidth: 800
                  formats: JPG
                )
              }
            }
          }
        }
      }
    }
  }
`;
